@import '../../../variable.less';

@media only screen
and (max-device-width: 480px) {
  #SectionMiddleContent {
    .content-head {
      margin-top: unset !important;
      padding: 20px !important;
      
      .content-head-title {
        h1.ant-typography {
          font-size: 1.7rem !important;
        }
        .content-head-title-sub {
          width: 80% !important;
        }
      }
  
      .ant-row {
        margin-top: 30px;
        padding: unset !important;
        display: flex;
        flex-flow: nowrap !important;
        overflow: auto !important;
  
        .ant-card-body {
          padding: 20px !important;
          height: 250px !important;
          .ant-image {
            width: 50px !important;
          }
        }
      }
    }

    .content-middle {
      padding: 20px !important;

      .content-middle-title {
        h1.ant-typography {
          margin-top: 20px !important;
          font-size: 1.7rem !important;
          line-height: 15px !important
        }
        .content-head-title-sub {
          width: 80% !important;
        }
      }

      .content-integration {
        margin: 50px 0 !important;

        .description {
          width: 100% !important;
          margin-bottom: 20px !important;
          h2.ant-typography {
            font-size: 1.3rem !important;
            margin-bottom: 15px !important;
          }
          .ant-typography {
            font-size: 14px !important;
          }
        }
      }
      .integration-sec {
        .ant-row {
          flex-flow: wrap-reverse !important;
        }
      }
    }

  }

}
@media only screen
and (orientation: landscape)
and (max-device-width: 950px) {
  #SectionMiddleContent {
    .content-head {
      margin-top: unset !important;
      padding: 20px !important;
      
      .content-head-title {
        h1.ant-typography {
          font-size: 1.7rem !important;
        }
        .content-head-title-sub {
          width: 100% !important;
        }
      }
  
      .ant-row {
        margin-top: 30px;
        padding: unset !important;
        display: flex;
        flex-flow: nowrap !important;
        overflow: auto !important;
  
        .ant-card-body {
          padding: 20px !important;
          height: 250px !important;

          .ant-image {
            width: 50px !important;
          }
        }
      }
    }

    .content-middle {
      padding: 20px !important;

      .content-middle-title {
        h1.ant-typography {
          margin-top: 20px !important;
          font-size: 1.7rem !important;
          line-height: 15px !important
        }
        .content-head-title-sub {
          width: 80% !important;
        }
      }

      .content-integration {
        margin: 50px 0 !important;

        .description {
          width: 100% !important;
          margin-bottom: 20px !important;
          h2.ant-typography {
            font-size: 1.3rem !important;
            margin-bottom: 15px !important;
          }
          .ant-typography {
            font-size: 14px !important;
          }
        }
      }
      .integration-sec {
        .ant-row {
          flex-flow: wrap-reverse !important;
        }
      }
    }

  }

}

/* ipad */
@media only screen
and (orientation: portrait)
and (min-device-width: 768px)
and (max-device-height: 1024px) {
  #SectionMiddleContent {
    .content-head {
      margin-top: unset !important;
      padding: 20px !important;
      
      .content-head-title {
        h1.ant-typography {
          font-size: 30px !important;
        }
        .content-head-title-sub {
          width: 80% !important;
        }
      }
  
      .ant-row {
        margin-top: 30px;
        padding: unset !important;
        display: flex;
        flex-flow: nowrap !important;
        overflow: auto !important;

        .ant-col-md-6 {
          padding: 0 15px !important;
        }
  
        .ant-card-body {
          padding: 15px !important;
          height: 265px !important;

          .ant-image {
            width: 50px !important;
          }

          .description {
            font-size: 14px;
          }
        }
      }
    }

    .content-middle {
      padding: 20px !important;

      .content-middle-title {
        h1.ant-typography {
          margin-top: 20px !important;
          font-size: 30px !important;
          line-height: 15px !important
        }
        .content-head-title-sub {
          width: 80% !important;
        }
      }

      .content-integration {
        margin: 50px 0 !important;

        .description {
          width: 100% !important;
          margin-bottom: 20px !important;
          h2.ant-typography {
            font-size: 20px !important;
            margin-bottom: 15px !important;
          }
          .ant-typography {
            font-size: 16px !important;
          }
        }
      }
      .integration-sec {
        .ant-row {
          flex-flow: wrap-reverse !important;
        }
      }
    }

  }
}

@media only screen
and (orientation: landscape)
and (min-device-width: 1024px)
and (max-device-height: 810px) {
  #SectionMiddleContent {
    .content-head {
      margin-top: unset !important;
      padding: 20px !important;
      
      .content-head-title {
        h1.ant-typography {
          font-size: 30px !important;
        }
        .content-head-title-sub {
          width: 100% !important;
        }
      }
  
      .ant-row {
        margin-top: 30px;
        padding: unset !important;
        display: flex;
        flex-flow: nowrap !important;
        overflow: auto !important;
  
        .ant-card-body {
          padding: 20px !important;
          height: 250px !important;
          .ant-image {
            width: 50px !important;
          }

          .description {
            font-size: 14px;
          }
        }
      }
    }

    .content-middle {
      padding: 40px !important;

      .content-middle-title {
        h1.ant-typography {
          margin-top: 20px !important;
          font-size: 30px !important;
          line-height: 15px !important
        }
        .content-head-title-sub {
          width: 80% !important;
          font-size: 16px;
        }
      }

      .content-integration {
        margin: 50px 0 !important;

        .description {
          width: 100% !important;
          margin-bottom: 20px !important;
          h2.ant-typography {
            font-size: 25px !important;
            margin-bottom: 15px !important;
          }
          .ant-typography {
            font-size: 16px !important;
          }
        }
      }
      .integration-sec {
        .ant-row {
          flex-flow: wrap-reverse !important;
        }
      }
    }

  }
}

/* ipad pro 12.9*/
@media only screen
and (orientation: portrait)
and (min-device-width: 834px)
and (max-device-height: 1366px) {
  #SectionMiddleContent {
    .content-head {
      margin-top: unset !important;
      padding: 20px !important;
      
      .content-head-title {
        h1.ant-typography {
          font-size: 30px !important;
        }
        .content-head-title-sub {
          width: 80% !important;
        }
      }
  
      .ant-row {
        margin-top: 30px;
        padding: unset !important;
        display: flex;
        flex-flow: nowrap !important;
        overflow: auto !important;

        .ant-col-md-6{
          padding: 0 15px !important;;
        }
  
        .ant-card-body {
          padding: 15px !important;
          height: 280px !important;
          .ant-image {
            width: 50px !important;
          }
        }
      }
    }

    .content-middle {
      padding: 20px !important;

      .content-middle-title {
        h1.ant-typography {
          margin-top: 20px !important;
          font-size: 30px !important;
          line-height: 15px !important
        }
        .content-head-title-sub {
          width: 80% !important;
        }
      }

      .content-integration {
        margin: 50px 0 !important;

        .description {
          width: 100% !important;
          margin-bottom: 20px !important;
          h2.ant-typography {
            font-size: 20px !important;
            margin-bottom: 15px !important;
          }
          .ant-typography {
            font-size: 16px !important;
          }
        }
      }
      .integration-sec {
        .ant-row {
          flex-flow: wrap-reverse !important;
        }
      }
    }

  }
}

@media only screen
and (orientation: landscape)
and (min-device-width: 1112px)
and (max-device-height: 1024px) {
  #SectionMiddleContent {
    .content-head {
      margin-top: unset !important;
      padding: 20px !important;
      
      .content-head-title {
        h1.ant-typography {
          font-size: 40px !important;
        }
        .content-head-title-sub {
          width: 100% !important;
          font-size: 18px;
        }
      }
  
      .ant-row {
        margin-top: 30px;
        padding: unset !important;
        display: flex;
        flex-flow: nowrap !important;
        overflow: auto !important;
  
        .ant-card-body {
          padding: 20px !important;
          height: 280px !important;

          h3.ant-typography {
            font-size: 20px;
          }
          .ant-typography {
            font-size: 18px;
          }
        }
      }
    }

    .content-middle {
      padding: 40px !important;

      .content-middle-title {
        h1.ant-typography {
          margin-top: 20px !important;
          font-size: 40px !important;
          line-height: 15px !important
        }
        .content-head-title-sub {
          width: 80% !important;
          font-size: 18px;
        }
      }

      .content-integration {
        margin: 50px 0 !important;

        .description {
          width: 100% !important;
          margin-bottom: 20px !important;
          h2.ant-typography {
            font-size: 30px !important;
            margin-bottom: 15px !important;
          }
          .ant-typography {
            font-size: 18px !important;
          }
        }
      }
      .integration-sec {
        .ant-row {
          flex-flow: wrap-reverse !important;
        }
      }
    }

  }
}