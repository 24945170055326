@import '../../../variable.less';

#SectionHeadContentAnalysis {
  background-color: @color-white;

  .content-head {
    padding: 40px 90px 45px;

    .content-title {
      width: 80%;
      h1.ant-typography {
        margin: 100px 0 70px;
      }
      .ant-typography.description {
        font-size: 18px;
        margin: 50px auto !important;
      }

      .ant-btn {
        margin-top: 70px;
        border-radius: 15px;
        padding: 5px 50px !important;
        color: white;
        font-weight: bold;
        height: 60px;
        line-height: 50px !important;
      }

    }
    .ant-image {
      width: 85%;
    }

    .content-bottom {
      padding: 100px 50px;
  
      .bottom-title {
        h2.ant-typography {
          margin-bottom: 10px !important;
        }
        .ant-typography.description {
          font-size: 18px;
          margin-bottom: 15px;
        }
  
      }
  
      .bottom-info {
        width: 85%;
        margin-bottom: 20px;
        .ant-image-img {
          width: 150%;
        }
  
        h2.ant-typography {
          margin: 20px 0 !important;
        }
  
        .ant-typography.description {
          font-size: 20px !important;
        }
  
      }
  
    }

  }

  
}