@import '../../../variable.less';

#SectionSecondContentAnalysis {

  .content-first {
    padding: 100px 0;
    .ant-image {
      .ant-image-img {
      }
    }

    .content-description {
      padding: 30px 60px 30px 20px;
      h2.ant-typography {
        margin: 30px 0 !important;
      }
      h3.ant-typography {
        margin-bottom: 20px !important;
      }
    }
    
    .content-testimoni {
      border-top: 1px solid #D4D4D4;
      padding: 30px 60px 30px 20px;
      .ant-typography {
        margin: 15px;
      }
    }

  }

  .content-second {
    background-color: @color-primary;
    padding: 100px 150px 0;
    text-align: center;

    h1.ant-typography {
      margin: 40px 0;
    }

    .ant-typography {
      color: @color-white;
    }

    .content-description {
      width: 70%;
      margin: auto auto 50px;
    }

    .ant-image {
      bottom: -8px;
    }

  }

  .content-third {
    padding: 100px 200px;
    text-align: center;

    h1.ant-typography {
      margin: 40px 0;
    }

    .content-description {
      width: 65%;
      margin: auto auto 80px;
    }

    .content-third-integration {
      padding: 100px;
    }

  }
  
}