@import './variable.less';

@font-face {
  font-family: ibmplex;
  src: url('./assets/fonts/IBMPlexSans-Regular.ttf');
}

@font-face {
  font-family: ibmplex;
  src: url('./assets/fonts/IBMPlexSans-Bold.ttf');
  font-weight: bold;
}

@font-face {
  font-family: ibmplex;
  src: url('./assets/fonts/IBMPlexSans-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: ibmplex;
  src: url('./assets/fonts/IBMPlexSans-Italic.ttf');
  font-style: italic;
}

@primary-color: @color-primary; // primary color for all components
@link-color: @color-primary; // link color
@success-color: @color-primary; // success state color
@warning-color: @color-gray; // warning state color
@error-color: @color-danger; // error state color
@font-size-base: 16px; // major text font size
@heading-color: @color-black; // heading text color
@text-color: @color-black; // major text color
@text-color-secondary: @color-gray; // secondary text color
@disabled-color: @color-gray; // disable state color
@border-radius-base: 4px; // major border radius
@border-color-base: @color-border; // major border color
@box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15); // major shadow for layers

@font-family: ibmplex;

h1.ant-typography {
  font-size: @font-size-32;
  line-height: 48px;
  margin: 0;
  font-weight: bold;
}

h2.ant-typography {
  font-size: @font-size-21;
  line-height: 31.5px;
  font-weight: bold;
  margin: 0 !important;
}

h3.ant-typography {
  font-size: @font-size-18;
  line-height: 27px;
  font-weight: bold;
  margin: 0px !important;
}

h4.ant-typography {
  font-size: @font-size-16;
  font-weight: 400;
  margin: 0;
}

h5.ant-typography {
  font-size: @font-size-14;
  font-weight: 400;
  margin: 0;
}

.text-success {
  color: @color-success !important;
}

.text-warning {
  color: @color-warning !important;
}

.text-danger {
  color: @color-danger !important;
}

.ant-card-body {
  padding: 15px;
  width: 100%;
}

.ant-tooltip {
  font-size: @font-size-10;
  z-index: 10000;

  .ant-tooltip-inner {
    padding: 4px;
    min-height: 0;
  }
}

.ant-select-item {
  font-size: @font-size-14;
}

.ant-card {
  box-shadow: 0 5px 15px #14006314;
  border-radius: 16px;
  margin-bottom: 24px;

  &.no-bottom {
    .ant-card-body {
      padding-bottom: 0px;

      #card-data,
      #card-data-loading {
        border: solid 0.5px #ccc;
      }
    }
  }

  .ant-card-head {
    border: none;
    padding: 16px;

    .ant-card-head-title {
      padding: 0px;
      font-size: @font-size-16;
    }
  }

  .ant-card-body {
    padding: 16px;
    min-height: 100px;
  }
}

.ant-checkbox-inner {
  border-color: @color-primary !important;
}

.ant-tabs-card {
  .ant-tabs-nav {
    margin-bottom: -16px;

    &::before {
      border: none;
    }

    .ant-tabs-tab {
      border: none;
      background-color: transparent;
      padding: 10px 24px 26px;

      .ant-tabs-tab-btn {
        color: @color-black;
        font-size: @font-size-16;
        line-height: 24px;
      }

      &.ant-tabs-tab-active {
        background-color: @color-white;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
      }
    }
  }

  .ant-tabs-content-holder {
    background-color: @color-white;
    padding: 40px 24px 24px;

    border-radius: 16px;

    min-height: calc(100vh - 234px);
  }
}

.ant-modal-content {
  border-radius: 16px;
}

.unbind {
  flex-direction: column;

  &.centered {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 240px;

    .unbind-text {
      text-align: center;
    }

    .ant-btn {
      margin-top: 32px;
    }
  }

  .unbind-text {
    font-size: @font-size-14;
    line-height: 21px;

    color: @color-grey-disabled;

    margin: 2px 0px 8px;
  }

  .ant-btn {
    height: 32px;
    color: @color-primary;
    border-color: @color-primary;

    font-size: @font-size-14;

    margin-bottom: 10px;
    max-width: 184px;

    span {
      overflow: hidden;
      text-overflow: ellipsis;

      width: 100%;
    }
  }
}

.loading {
  background: #e5e5e5;
  background-image: linear-gradient(
    to right,
    #e5e5e5 0%,
    #f6f7f8 20%,
    #e5e5e5 40%,
    #e5e5e5 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 800px;
  display: inline-block;
  position: relative;

  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
}

@keyframes shimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.ant-image {
  width: 100%
}

.ant-input {
  border-radius: 10px;
}

@media only screen and (max-device-width: 480px) {
  .ant-btn {
    padding: 2.4px 10px !important;
  }
  a.ant-btn {
    padding-top: 2.4px !important;
  }
  .ant-typography {
    font-size: 14px !important;
    line-height: normal !important;
  }
  h1.ant-typography {
    font-size: 1.7rem !important;
    line-height: normal !important;
  }
  h2.ant-typography {
    font-size: 1.3rem !important;
    line-height: normal !important;
  }
  h3.ant-typography {
    font-size: 1.12rem !important;
    line-height: normal !important;
  }
}

@media only screen
and (orientation: landscape)
and (max-device-width: 950px) {
  .ant-btn {
    padding: 2.4px 10px !important;
  }
  a.ant-btn {
    padding-top: 2.4px !important;
  }
  .ant-typography {
    font-size: 14px !important;
    line-height: normal !important;
  }
  h1.ant-typography {
    font-size: 3rem !important;
    line-height: normal !important;
  }
  h2.ant-typography {
    font-size: 1.3rem !important;
    line-height: normal !important;
  }
}

@media only screen
and (min-width: 1500px) {
  button {
    font-size: unset !important;
  }
  .ant-typography {
    font-size: 1.7rem !important;
    line-height: normal !important;
  }
  h1.ant-typography {
    font-size: 3rem !important;
    line-height: normal !important;
  }
  h2.ant-typography {
    font-size: 2rem !important;
    line-height: normal !important;
  }
  h3.ant-typography {
    font-size: 1.5rem !important;
    line-height: normal !important;
    margin-bottom: 15px;
  }
}

@media only screen
and (min-width: 1800px) {
  .ant-input {
    font-size: 24px;
  }
  h1.ant-typography {
    font-size: 4rem !important;
  }
  h2.ant-typography {
    font-size: 2.5rem !important;
  }
  h3.ant-typography {
    font-size: 1.5rem !important;
    margin-bottom: 15px;
  }
}

.slide-pane__overlay {
  z-index: 9;
}
.slide-pane {
  height: fit-content !important;
}
.sidebar-mobile {
  max-height: 100vh;
  padding: 0px;

  .slide-pane__content {
    padding: 0px;
    max-height: 100vh;
  }
}

.ant-image-img {
  margin: auto;
}