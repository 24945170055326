@import '../../../variable.less';

@media only screen and (max-device-width: 480px) {
  #footer-landing {
    padding: 20px !important;
  
    .detail-address {
      width: 100% !important;
    }

    .detail-link {
      margin-bottom: 20px !important;

      div {
        width: 35px;
        height: 35px;
      }
    }
  
    .ant-btn-primary {
      font-size: unset !important;
    }

    .footer-indentity {
      margin-top: unset !important;
      .ant-row {
        .ant-col {
          text-align: center !important;
          margin-top: 10px !important;
          .foot-logos {
            margin: auto !important;
          }
        }
      }
    }
  }
  
}

@media only screen
and (orientation: landscape)
and (max-device-width: 950px) {
  #footer-landing {
    padding: 20px !important;
    h1.ant-typography {
      font-size: 30px !important;
    }
    h3.ant-typography {
      font-size: 20px !important;
    }
    .detail-address {
      width: 100% !important;
    }

    .detail-link {
      margin-bottom: 20px !important;

      div {
        width: 35px;
        height: 35px;
      }
    }
  
    .ant-btn-primary {
      font-size: unset !important;
    }

    .footer-indentity {
      margin-top: unset !important;
      .ant-row {
        .ant-col {
          margin-top: 10px !important;
          .foot-logos {
            width: 70%;
          }
        }
      }
    }
  }
  
}

/* ipad */
@media only screen
and (orientation: portrait)
and (min-device-width: 768px)
and (max-device-height: 1024px) {
  #footer-landing {
    padding: 40px !important;
  
    .detail-address {
      width: 70% !important;
    }

    .detail-link {
      margin-bottom: 20px !important;

      div {
        width: 35px;
        height: 35px;
      }
    }
  
    .ant-btn-primary {
      font-size: unset !important;
    }

    .footer-indentity {
      margin-top: unset !important;
      .ant-row {
        .ant-col {
          margin-top: 10px !important;
          .foot-logos {
            width: 60% !important;
          }
        }
      }
    }
  }
}

@media only screen
and (orientation: landscape)
and (min-device-width: 1024px)
and (max-device-height: 810px) {
  #footer-landing {
    padding: 40px !important;
    h1.ant-typography {
      font-size: 30px !important;
    }
    h3.ant-typography {
      font-size: 20px !important;
    }
    .detail-address {
      width: 70% !important;
    }

    .detail-link {
      margin-bottom: 20px !important;

      div {
        width: 35px;
        height: 35px;
      }
    }
  
    .ant-btn-primary {
      font-size: unset !important;
    }

    .footer-indentity {
      margin-top: unset !important;
      .ant-row {
        .ant-col {
          margin-top: 10px !important;
          .foot-logos {
            width: 60%;
          }
        }
      }
    }
  }
}
/* ipad pro 12.9*/
@media only screen
and (orientation: portrait)
and (min-device-width: 834px)
and (max-device-height: 1366px) {
  #footer-landing {
    padding: 50px !important;
  
    .detail-address {
      width: 70% !important;
      font-size: 16px;
    }

    .detail-link {
      margin-bottom: 20px !important;

      div {
        width: 35px;
        height: 35px;
      }
    }
  
    .ant-btn-primary {
      font-size: unset !important;
    }

    .footer-indentity {
      margin-top: unset !important;
      .ant-row {
        .ant-col {
          margin-top: 10px !important;
          .foot-logos {
            width: 50% !important;
          }
        }
      }
    }
  }
}

@media only screen
and (orientation: landscape)
and (min-device-width: 1112px)
and (max-device-height: 1024px) {
  #footer-landing {
    padding: 50px !important;
    h1.ant-typography {
      font-size: 30px !important;
    }
    h3.ant-typography {
      font-size: 20px !important;
    }
    .detail-address {
      width: 70% !important;
      font-size: 18px;
    }

    .detail-link {
      margin-bottom: 20px !important;

      div {
        width: 35px;
        height: 35px;
      }
    }
  
    .ant-btn-primary {
      font-size: unset !important;
    }

    .footer-indentity {
      margin-top: unset !important;
      .ant-row {
        .ant-col {
          margin-top: 10px !important;
          .foot-logos {
            width: 70%;
          }
        }
      }
    }
  }
}