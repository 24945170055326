@import '../../../variable.less';

@media only screen and (max-device-width: 480px) {
  #SectionBottomContentAbout {
    padding: 20px !important;
    .content-head {
      padding: unset !important;
      .content-title {
        margin-bottom: 30px !important;
        h1.ant-typography {
          font-size: 30px !important;
          line-height: normal !important;
          margin-top: 30px;
        }
      }

      .ant-row {
        flex-flow: wrap-reverse !important;
      }
    }
  }
}

@media only screen
and (orientation: landscape) 
and (max-device-width: 950px) {
  #SectionBottomContentAbout {
    padding: 20px !important;
    .content-head {
      .content-title {
        margin-bottom: 30px !important;
        h1.ant-typography {
          font-size: 24px !important;
          line-height: normal !important;
        }
      }

      .ant-row {
        flex-flow: wrap-reverse !important;
      }
    }
  }
}

/* ipad */
@media only screen
and (orientation: portrait)
and (min-device-width: 768px)
and (max-device-height: 1024px) {
  #SectionBottomContentAbout {
    padding: 40px !important;
    .content-head {
      padding: unset !important;
      .content-title {
        margin-bottom: 30px !important;
        h1.ant-typography {
          font-size: 30px !important;
          line-height: normal !important;
          margin: unset !important;
        }

        .ant-typography.description{
          font-size: 16px !important;
        }
      }

      .ant-row {
        flex-flow: wrap-reverse !important;
      }
    }
  }
}

@media only screen
and (orientation: landscape)
and (min-device-width: 1024px)
and (max-device-height: 810px) {
  #SectionBottomContentAbout {
    padding: 60px 60px 0 60px !important;
    .content-head {
      .content-title {
        margin-bottom: 30px !important;
        h1.ant-typography {
          font-size: 30px !important;
          line-height: normal !important;
          margin: unset !important;
        }
      }

      .ant-row {
        flex-flow: wrap-reverse !important;
      }
    }
  }
}
/* ipad pro 12.9*/
@media only screen
and (orientation: portrait)
and (min-device-width: 834px)
and (max-device-height: 1366px) {
  #SectionBottomContentAbout {
    padding: 40px !important;
    .content-head {
      padding: unset !important;
      .content-title {
        margin-bottom: 30px !important;
        h1.ant-typography {
          font-size: 35px !important;
          line-height: normal !important;
          margin: unset !important;
        }

        .ant-typography.description{
          font-size: 18px !important;
        }
      }

      .ant-row {
        flex-flow: wrap-reverse !important;
      }
    }
  }
}

@media only screen
and (orientation: landscape)
and (min-device-width: 1112px)
and (max-device-height: 1024px) {
  #SectionBottomContentAbout {
    padding: 60px 60px 0 60px !important;
    .content-head {
      .content-title {
        margin-bottom: 30px !important;
        h1.ant-typography {
          font-size: 45px !important;
          line-height: normal !important;
          margin: unset !important;
        }
      }

      .ant-row {
        flex-flow: wrap-reverse !important;
      }
    }
  }
}