@import '../../../variable.less';

#footer-landing {
  padding: 40px 80px;
  border-radius: 15px 15px 0 0;
  background-color: @color-primary;
  
  .ant-typography {
    color: @color-white;
  }
  
  h2.ant-typography {
    margin-bottom: 20px !important;
  }

  h3.ant-typography {
    margin-bottom: 30px !important;
  }

  .detail-address {
    width: 45%;
    white-space: break-spaces;
    @media only screen
    and (min-width: 1400px) {
      .ant-typography {
        font-size: 18px !important;
      }
    }
    div {
      margin-bottom: 20px;
    }
  }

  .detail-link {
    margin-top: 40px;
    display: flex;
    align-items: center;

    div {
      width: 45px;
      height: 45px;
      background-color: @color-white;
      padding: 5px 0;
      border-radius: 100%;
      text-align: center;
      margin: 0 10px;
      
      .ant-typography {
        color: @color-primary;
        // font-size: 18px !important;
      }
    }

  }

  .form-contact-us {

    form {
      
      .ant-form-item-label {
        padding: unset;
      }

      .ant-form-item-label > label {
        color: @color-white;
      }

      .ant-input {
        color: white;
      }

      .ant-form-item-control-input {
        area, input:not([type='range']), textarea {
          border-color: #FFFFFF99;
          background-color: transparent;
        }
        .ant-input:hover {
          border-color: @color-white;
          color: @color-white;
        }
        .ant-input:focus {
          border-color: @color-white;
          color: @color-white;
        }
        
      }
      
      .ant-form-item-explain.ant-form-item-explain-error {
        font-size: 12px;
      }

      .ant-btn-primary {
        width: 100%;
        font-size: 14px;
        border-color: @color-secondary;
        background-color: @color-secondary;
      }

      .ant-btn-primary:hover, .ant-btn-primary:focus {
        background-color: @color-secondary-focus;
      }

    }

  }

  .footer-indentity {
    border-top: 1px solid #FFFFFF99;
    margin-top: 50px;
    padding-top: 20px;
  }

  .foot-logos {
    width: 40%;
  }
}
