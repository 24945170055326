@import '../../../variable.less';

#SectionMiddleContent {
  
  .content-head {
    margin-top: 30px;
    padding: 60px 90px;

    .content-head-title {
      text-align: center;
      
      h1.ant-typography {
        margin-bottom: 15px !important;
      }

      .content-head-title-sub {
        margin: auto;
        width: 40%;
      }
      
    }

    .ant-card-body {
      padding: 40px;
      height: 300px;

      @media only screen
      and (max-width: 1400px) {
        padding: 20px;
        height: 300px;
      }

      @media only screen
      and (min-width: 1400px)
      and (max-width: 1600px) {
        height: 350px;
      }

      .description {
        @media only screen
        and (min-width: 1400px){
          .ant-typography {
            font-size: 18px !important;
          }
        }
      }
    }

    .ant-row {
      margin-top: 80px;

      .description {
        margin-top: 20px;
        font-size: 18px;
      }
      
    }

  }

  .content-middle {
    padding: 60px 90px;
    background-color: @color-primary;
    color: @color-white;
    
    .ant-typography {
      color: @color-white;
    }

    .content-middle-title {
      text-align: center;
      
      h1.ant-typography {
        margin-bottom: 15px !important;
        color: @color-white;
      }

      .content-head-title-sub {
        margin: auto;
        width: 40%;
      }
      
    }

    .content-integration {
      margin: 100px 0;

      .description {
        width: 85%;
        height: fit-content;
        align-self: center;
        
        h2.ant-typography {
          margin-bottom: 80px !important;
        }

      }
    }

  }
  
}
