@import '../../../variable.less';

#SectionBottomContent {
  padding: 100px 90px;

  .content-head {
    padding: 30px 0;
    .content-title {
      width: 80%;
      h1.ant-typography {
        margin: 40px 0 30px !important;
      }
      h3.ant-typography {
        margin: 30px 0 !important;
      }
      .ant-typography.description {
        font-size: 18px;
      }

    }

    .ant-row .ant-col {
      .box {
        width: 95%;
        height: 120px;
        border: 1px solid #e0e0e0;
        border-radius: 20px;
        padding: 10px 20px;
        margin-bottom: 20px;
        color: @color-primary;

        i {
          font-size: 30px;
          color: @color-primary;
        }

        .ant-typography {
          font-size: 12px;
        }

      }
    }

    .ant-typography.benchmark-industry {
      font-size: 16px !important;
    }

  }

  .content-certificated {
    padding: 80px 0;
    text-align: center;

    h1.ant-typography {
      margin: 20px 0 60px !important;
    }
    
    .ant-row {
      align-items: center;
      justify-content: space-between;

      .ant-col {
        border-radius: 10px;
        margin: 10px;
        padding: 20px 50px !important;
        background-color: @color-white;

        .img-cert {
          width: 90% !important;
          margin: auto;
        }
      }

    }

  }

  .content-testimoni {
    padding: 30px 0;
    text-align: center;

    h1.ant-typography {
      margin: 20px 0 30px !important;
    }

    .ant-image {
      margin: 0 0 30px !important;
    }
    
    .section-carousel {
      position: relative;
      padding: 0 50px;

      .action-button-slide {
        width: 80%;
        margin: auto;
        display: flex;
        justify-content: space-between;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
  
        button {
          background-color: transparent;
          padding: 10px;
          height: 50px;
          width: 50px;
        }
      }

      .content-carousel {
        padding: 20px;
        height: max-content;

        .description {
          width: 50%;
          margin: 30px auto;
          font-size: 14px;
        }

      }

      .ant-carousel .slick-dots-bottom {
        bottom: -35px;
      }

      .ant-carousel .slick-dots li.slick-active button {
        background: #1f36c1;
      }

      .ant-carousel .slick-dots li button {
        color: transparent;
        background: #1f36c1;
      }
      
      .slick-arrow {
        width: 10px;
        height: 10px;
      }

      .slick-next {
        border: solid black;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
      }

      .slick-prev {
        border: solid black;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
      }

    }

  }
  
}