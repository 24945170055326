@import '../../../variable.less';

@media only screen and (max-device-width: 480px) {
  #SectionSecondContentAnalysis {
    .content-first {
      padding: 0 0 50px 0 !important;

      .content-description {
        h2.ant-typography {
          font-size: 30px !important;
          margin-bottom: 20px !important;
        }
      }

      .ant-row {
        flex-flow: wrap-reverse !important;
      }
    }

    .content-second {
      padding: 20px 10px !important;

      h1.ant-typography {
        font-size: 24px !important;
        line-height: normal !important;
        margin: 20px 0 !important;
      }

      .content-description {
        width: 100% !important;
        padding: 0 5px !important;
        .ant-typography {
          font-size: 14px !important;
        }
      }

      .ant-image {
        bottom: -30px !important;
      }
    }

    .content-third {
      padding: unset !important;

      h1.ant-typography {
        font-size: 24px !important;
        line-height: normal !important;
        margin: 40px 0 20px 0 !important;
      }

      .content-description {
        width: 100% !important;
        padding: 0 5px !important;
        margin-bottom: 20px !important;
        .ant-typography {
          font-size: 14px !important;
        }
      }

      .content-third-integration {
        padding: 0 20px 20px !important;
      }
    }
  }
}

@media only screen
and (orientation: landscape) 
and (max-device-width: 950px) {
  #SectionSecondContentAnalysis {
    .content-first {
      padding: 0 0 50px 0 !important;
  
      .content-description {}
  
      .ant-row {
        flex-flow: wrap-reverse !important;
      }
    }
  
    .content-second {
      padding: 20px 100px !important;
  
      h1.ant-typography {
        font-size: 24px !important;
        line-height: normal !important;
        margin: 20px 0 !important;
      }
  
      .content-description {
        width: 100% !important;
        padding: 0 5px !important;
        .ant-typography {
          font-size: 14px !important;
        }
      }
  
      .ant-image {
        bottom: -30px !important;
      }
    }
  
    .content-third {
      padding: 20px 100px !important;
  
      h1.ant-typography {
        font-size: 24px !important;
        line-height: normal !important;
        margin: 40px 0 20px 0 !important;
      }
  
      .content-description {
        width: 100% !important;
        padding: 0 5px !important;
        margin-bottom: 20px !important;
        .ant-typography {
          font-size: 14px !important;
        }
      }
  
      .content-third-integration {
        padding: 40px !important;
      }
    }
  }
  
}

/* ipad */
@media only screen
and (orientation: portrait)
and (min-device-width: 768px)
and (max-device-height: 1024px) {
  #SectionSecondContentAnalysis {
    .content-first {
      padding: 0 0 50px 0 !important;

      .content-description {
        padding: 10px 40px 10px 10px;
        h2.ant-typography {
          font-size: 25px !important;
          margin-bottom: 20px !important;
        }
      }

      .ant-row {
        flex-flow: wrap-reverse !important;
      }
    }

    .content-second {
      padding: 20px 40px !important;

      h1.ant-typography {
        font-size: 24px !important;
        line-height: normal !important;
        margin: 20px 0 !important;
      }

      .content-description {
        width: 70% !important;
        padding: 0 5px !important;
        .ant-typography {
          font-size: 14px !important;
        }
      }

      .ant-image {
        bottom: -30px !important;
      }
    }

    .content-third {
      padding: 40px !important;

      h1.ant-typography {
        font-size: 30px !important;
        line-height: normal !important;
        margin: 40px 0 20px 0 !important;
      }

      .content-description {
        width: 70% !important;
        padding: 0 5px !important;
        margin-bottom: 20px !important;
        .ant-typography {
          font-size: 14px !important;
        }
      }

      .content-third-integration {
        padding: 0 40px 40px !important;
      }
    }
  }
}

@media only screen
and (orientation: landscape)
and (min-device-width: 1024px)
and (max-device-height: 810px) {
  #SectionSecondContentAnalysis {
    .content-first {
      padding: 0 0 50px 0 !important;
  
      .content-description {
        h2.ant-typography {
          font-size: 30px !important;
          margin-bottom: 20px !important;
        }
      }
  
      .ant-row {
        flex-flow: wrap-reverse !important;
      }
    }
  
    .content-second {
      padding: 20px 10px !important;
  
      h1.ant-typography {
        font-size: 30px !important;
        line-height: normal !important;
        margin: 20px 0 !important;
      }
  
      .content-description {
        width: 70% !important;
        padding: 0 5px !important;
        .ant-typography {
          font-size: 14px !important;
        }
      }
  
      .ant-image {
        bottom: -30px !important;
      }
    }
  
    .content-third {
      padding: unset !important;
  
      h1.ant-typography {
        font-size: 30px !important;
        line-height: normal !important;
        margin: 40px 0 20px 0 !important;
      }
  
      .content-description {
        width: 70% !important;
        padding: 0 5px !important;
        margin-bottom: 20px !important;
        .ant-typography {
          font-size: 14px !important;
        }
      }
  
      .content-third-integration {
        padding: 0 50px 50px !important;
      }
    }
  }
}
/* ipad pro 12.9*/
@media only screen
and (orientation: portrait)
and (min-device-width: 834px)
and (max-device-height: 1366px) {
  #SectionSecondContentAnalysis {
    .content-first {
      padding: 20px 0 50px 0 !important;

      .content-description {
        padding: 10px 40px 10px 10px;
        h2.ant-typography {
          font-size: 25px !important;
          margin-bottom: 20px !important;
        }
      }

      .ant-row {
        flex-flow: wrap-reverse !important;
      }
    }

    .content-second {
      padding: 20px 150px !important;

      h1.ant-typography {
        font-size: 24px !important;
        line-height: normal !important;
        margin: 20px 0 !important;
      }

      .content-description {
        width: 100% !important;
        padding: 0 5px !important;
        .ant-typography {
          font-size: 14px !important;
        }
      }

      .ant-image {
        bottom: -30px !important;
      }
    }

    .content-third {
      padding: 40px 150px !important;

      h1.ant-typography {
        font-size: 30px !important;
        line-height: normal !important;
        margin: 40px 0 20px 0 !important;
      }

      .content-description {
        width: 100% !important;
        padding: 0 5px !important;
        margin-bottom: 20px !important;
        .ant-typography {
          font-size: 14px !important;
        }
      }

      .content-third-integration {
        padding: 40px !important;
      }
    }
  }
}

@media only screen
and (orientation: landscape)
and (min-device-width: 1112px)
and (max-device-height: 1024px) {
  #SectionSecondContentAnalysis {
    .content-first {
      padding: 30px 0 50px 0 !important;
  
      .content-description {
        h2.ant-typography {
          font-size: 30px !important;
          margin-bottom: 20px !important;
        }
      }
  
      .ant-row {
        flex-flow: wrap-reverse !important;
      }
    }
  
    .content-second {
      padding: 20px 200px !important;
  
      h1.ant-typography {
        font-size: 30px !important;
        line-height: normal !important;
        margin: 20px 0 !important;
      }
  
      .content-description {
        width: 70% !important;
        padding: 0 5px !important;
        .ant-typography {
          font-size: 14px !important;
        }
      }
  
      .ant-image {
        bottom: -30px !important;
      }
    }
  
    .content-third {
      padding: 40px 200px !important;
  
      h1.ant-typography {
        font-size: 45px !important;
        line-height: normal !important;
        margin: 40px 0 20px 0 !important;
      }
  
      .content-description {
        width: 100% !important;
        padding: 0 5px !important;
        margin-bottom: 20px !important;
        .ant-typography {
          font-size: 18px !important;
        }
      }
  
      .content-third-integration {
        padding: 50px !important;
      }
    }
  }
}