@import '../../../variable.less';

@media only screen
and (max-device-width: 480px) {
    #SectionHeadContent {

      .content-head {
        .ant-row {
          flex-flow: wrap-reverse !important;
        }
        .content-case {
          .ant-row {
            flex-flow: row wrap !important;
          }
        }
        .ant-image {
          width: 100% !important;
        }
      }
    
      .content-head {
        padding: 20px !important;

        .content-title {
    
          .ant-btn {
            font-size: 20px !important;
            height: 50px !important;
            padding: 0 50px !important;
          }
        }

        .content-case {
          padding: 40px 30px;
        }
      }
    
      h1.ant-typography {
        margin: 20px 0 15px !important;
        font-size: 30px !important;
        line-height: normal !important;
      }
    
      .ant-typography.description {
        font-size: 14px !important;
      }
    
      .ant-btn {
        width: 100% !important;
        margin-top: 30px;
        border-radius: 10px;
        padding: 5px 50px !important;
        color: white;
        font-weight: bold;
        height: 50px;
        line-height: 40px !important;
      }
    }
}

@media only screen
and (orientation: landscape)
and (max-device-width: 950px) {
  #SectionHeadContent {

    .content-head {
      .ant-row {
        flex-flow: wrap-reverse !important;
      }
      .content-case {
        .ant-row {
          flex-flow: row wrap !important;
        }
      }
    }
  
    .content-head {
      padding: 20px !important;

      .content-title {
  
        .ant-btn {
          font-size: 20px !important;
          height: 50px !important;
          padding: 0 50px !important;
        }
      }

      .content-case {
        padding: 40px 30px;
      }
    }
  
    h1.ant-typography {
      font-size: 30px !important;
      line-height: normal !important;
    }
  
    .ant-typography.description {
      font-size: 14px !important;
    }
  
    .ant-btn {
      width: 100% !important;
      margin-top: 30px;
      border-radius: 10px;
      padding: 5px 50px !important;
      color: white;
      font-weight: bold;
      height: 50px;
      line-height: 40px !important;
    }
  }
}

/* ipad */
@media only screen
and (orientation: portrait)
and (min-device-width: 768px)
and (max-device-height: 1024px) {
  #SectionHeadContent {

    .content-head {
      .ant-row {
        flex-flow: wrap-reverse !important;
      }
      .content-case {
        .ant-row {
          flex-flow: row wrap !important;
        }
      }
    }
  
    .content-head {
      padding: 40px !important;

      .content-title {
  
        .ant-btn {
          font-size: 20px !important;
          height: 50px !important;
          padding: 0 50px !important;
        }
      }

      .content-case {
        padding: 0 40px;
        .ant-col-xs-12 {
          padding: 0 15px !important;
        }
      }
    }
  
    h1.ant-typography {
      margin: 20px 0 15px !important;
      font-size: 25px !important;
      line-height: normal !important;
    }
  
    .ant-typography.description {
      font-size: 14px !important;
    }
  
    .ant-btn {
      margin-top: 30px;
      border-radius: 10px;
      padding: 5px 50px !important;
      color: white;
      font-weight: bold;
      height: 50px;
      line-height: 40px !important;
    }
  }
}

@media only screen
and (orientation: landscape)
and (min-device-width: 1024px)
and (max-device-height: 810px) {
  #SectionHeadContent {

    .content-head {
      .ant-row {
        flex-flow: wrap-reverse !important;
      }
      .content-case {
        .ant-row {
          flex-flow: row wrap !important;
        }
      }
    }
  
    .content-head {
      padding: 50px !important;

      .content-title {
  
        .ant-btn {
          font-size: 20px !important;
          height: 50px !important;
          padding: 0 50px !important;
        }
      }

      .content-case {
        padding: 0 50px;
        .ant-col-xs-12 {
          padding: 0 15px !important;
        }
      }
    }
  
    h1.ant-typography {
      font-size: 30px !important;
      line-height: normal !important;
    }
  
    .ant-typography.description {
      font-size: 14px !important;
    }
  
    .ant-btn {
      margin-top: 30px;
      border-radius: 10px;
      padding: 5px 50px !important;
      color: white;
      font-weight: bold;
      height: 50px;
      line-height: 40px !important;
    }
  }
}

/* ipad pro 12.9*/
@media only screen
and (orientation: portrait)
and (min-device-width: 834px)
and (max-device-height: 1366px) {
  #SectionHeadContent {

    .content-head {
      .ant-row {
        flex-flow: wrap-reverse !important;
      }
      .content-case {
        .ant-row {
          flex-flow: row wrap !important;
        }
      }
    }
  
    .content-head {
      padding: 40px !important;

      .content-title {
  
        .ant-btn {
          font-size: 20px !important;
          height: 50px !important;
          padding: 0 50px !important;
        }
      }

      .content-case {
        padding: 0 50px;
        .ant-col-xs-12 {
          padding: 0 15px !important;
        }
      }
    }
  
    h1.ant-typography {
      margin: 20px 0 15px !important;
      font-size: 35px !important;
      line-height: normal !important;
    }

    h3.ant-typography {
      font-size: 25px !important;
      line-height: normal !important;
    }
  
    .ant-typography.description {
      font-size: 16px !important;
    }
  
    .ant-btn {
      margin-top: 30px;
      border-radius: 10px;
      padding: 5px 50px !important;
      color: white;
      font-weight: bold;
      height: 50px;
      line-height: 40px !important;
    }
  }
}

@media only screen
and (orientation: landscape)
and (min-device-width: 1112px)
and (max-device-height: 1024px) {
  #SectionHeadContent {

    .content-head {
      .ant-row {
        flex-flow: wrap-reverse !important;
      }
      .content-case {
        .ant-row {
          flex-flow: row wrap !important;
        }
      }
    }
  
    .content-head {
      padding: 50px !important;

      .content-title {
  
        .ant-btn {
          font-size: 20px !important;
          height: 50px !important;
          padding: 0 50px !important;
        }
      }

      .content-case {
        padding: 0 40px;
        .ant-col-xs-12 {
          padding: 0 20px !important;
        }
      }
    }
  
    h1.ant-typography {
      font-size: 45px !important;
      line-height: normal !important;
    }

    h3.ant-typography {
      font-size: 25px !important;
      line-height: normal !important;
    }
  
    .ant-typography.description {
      font-size: 18px !important;
    }
  
    .ant-btn {
      margin-top: 30px;
      border-radius: 10px;
      padding: 5px 50px !important;
      color: white;
      font-weight: bold;
      height: 50px;
      line-height: 40px !important;
    }
  }
}