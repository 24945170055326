@import '../../../variable.less';

#header-landing {
  position: fixed;
  z-index: 9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 100px;
  padding: 10px 80px;
  background-color: @color-white;
  box-shadow: 0 -15px 20px 5px #b3b3b3;

  .ant-menu-horizontal {
    border-bottom: none;
  }

  .header-logo {
    width: 20%;
    height: fit-content;
  }

  .header-menu {
    width: 100%;
    height: fit-content;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;

    @media only screen
    and (min-width: 1500px) {
      font-size: 20px;
    }
  }

  .header-button {
    height: fit-content;
    display: flex;
    align-items: center;
    div {
      display: flex;
    }
    .ant-btn {
      height: 35px;
      font-weight: bold;

      @media only screen
      and (min-width: 1500px) {
        height: 45px;
      }
    }

    .button-sign-in {
      color: unset;
      border: unset;
      box-shadow: unset;
      padding: 0 20px !important;
      margin-right: 10px;
    }

    .button-sign-in:hover {
      color: @color-primary;
      border: 1px solid @color-primary;
      box-shadow: unset;
      transition: 0.125s;
      padding: 0 20px !important;
    }

    .button-join {
      color: white;
      font-weight: bold;
      margin-right: 15px;
      padding: 0 25px !important;

      @media only screen
      and (min-width: 1500px) {
        padding: 0 30px !important;
      }
    }
  }

}

@media only screen
and (orientation: portrait)
and (max-device-width: 480px) {
  #header-mobile{
    position: fixed;
    width: 100%;
    height: 60px;
    padding: 0 20px;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    box-shadow: 0 -15px 20px 5px #b3b3b3;
  
    .header-logo {
      width: 30%;
    }
  
    .button-sign-in {
      color: unset;
      border: unset;
      box-shadow: unset;
      padding: 0 20px !important;
      margin-right: 10px;
    }
  
    .button-sign-in:hover {
      color: @color-primary;
      border: 1px solid @color-primary;
      box-shadow: unset;
      transition: 0.125s;
      padding: 0 20px !important;
    }
  
    .button-join {
      color: white;
      font-weight: bold;
    }
  }
  
  #header-mobile-content{
    display: flex;
    align-items: center;
    padding: 20px;
    background-color: white;
  
    .header-logo {
      width: 30%;
      margin: auto;
    }
  }

  .header-menu-mobile {
    padding: 0 15px;
    .ant-menu-title-content {
      color: @color-border-light-gray !important;
      font-weight: 600 !important;
    }
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: unset !important;
      .ant-menu-title-content {
        color: @color-primary !important;
      }
    }
  }

  .header-button-mobile {
    width: 90%;
    margin: 20px auto;
    text-align: center;
    
    .ant-radio-group {
      padding: 5px !important;
      border: 1px solid #EAEAEA;
      border-radius: 8px;

      .ant-radio-button-wrapper{
        border: unset !important;
        border-radius: 5px;

        .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
          color: #fff;
          background: @color-primary;
          border-color: @color-primary;
          border-radius: 5px;
        }

      }
      .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
        background-color: white;
      }
    }

    .button-sign-in {
      color: unset;
      border: unset;
      box-shadow: unset;
      font-weight: bold;
      color: @color-primary !important;
      padding: 0 20px !important;
      margin-bottom: 20px;
    }
  
    .button-sign-in:hover {
      color: @color-primary;
      border: 1px solid @color-primary;
      box-shadow: unset;
      transition: 0.125s;
      padding: 0 20px !important;
    }
  
    .button-join {
      margin: 30px 0;
      width: 100%;
      color: white;
      font-weight: bold;
      font-size: 20px !important;
    }
  }
}

@media only screen
and (orientation: landscape)
and (max-device-width: 950px) {
  #header-mobile{
    position: fixed;
    width: 100%;
    height: 80px;
    padding: 0 20px;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    box-shadow: 0 -15px 20px 5px #b3b3b3;
  
    .header-logo {
      width: 25%;
    }
  
    .button-sign-in {
      color: unset;
      border: unset;
      box-shadow: unset;
      padding: 0 20px !important;
      margin-right: 10px;
    }
  
    .button-sign-in:hover {
      color: @color-primary;
      border: 1px solid @color-primary;
      box-shadow: unset;
      transition: 0.125s;
      padding: 0 20px !important;
    }
  
    .button-join {
      color: white;
      font-weight: bold;
    }
  }
  
  #header-mobile-content{
    display: flex;
    align-items: center;
    padding: 20px;
    background-color: white;
  
    .header-logo {
      width: 25%;
      margin: auto;
    }
  }

  .header-menu-mobile {
    padding: 0 15px;
    .ant-menu-title-content {
      color: @color-border-light-gray !important;
      font-weight: 600 !important;
    }
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: unset !important;
      .ant-menu-title-content {
        color: @color-primary !important;
      }
    }
  }

  .header-button-mobile {
    width: 90%;
    margin: 20px auto;
    text-align: center;
    .button-sign-in {
      color: unset;
      border: unset;
      box-shadow: unset;
      padding: 0 20px !important;
    }
  
    .button-sign-in:hover {
      color: @color-primary;
      border: 1px solid @color-primary;
      box-shadow: unset;
      transition: 0.125s;
      padding: 0 20px !important;
    }
  
    .button-join {
      margin: 30px 0 20px;
      width: 100%;
      color: white;
      font-weight: bold;
      font-size: 25px !important;
      border-radius: 10px;
      height: 45px;
    }
  }
}

/* ipad */
@media only screen
and (orientation: portrait)
and (min-device-width: 768px)
and (max-device-height: 1024px) {
  #header-mobile{
    position: fixed;
    width: 100%;
    height: 80px;
    padding: 0 40px;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    box-shadow: 0 -15px 20px 5px #b3b3b3;
  
    .header-logo {
      width: 25%;
    }
  
    .button-sign-in {
      color: unset;
      border: unset;
      box-shadow: unset;
      padding: 0 20px !important;
      margin-right: 10px;
    }
  
    .button-sign-in:hover {
      color: @color-primary;
      border: 1px solid @color-primary;
      box-shadow: unset;
      transition: 0.125s;
      padding: 0 20px !important;
    }
  
    .button-join {
      color: white;
      font-weight: bold;
    }
  }
  
  #header-mobile-content{
    display: flex;
    align-items: center;
    padding: 20px 40px;
    background-color: white;
  
    .header-logo {
      width: 25%;
      margin: auto;
    }
  }
  .header-menu-mobile {
    padding: 0 30px;
    .ant-menu-title-content {
      color: @color-border-light-gray !important;
      font-weight: 600 !important;
    }
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: unset !important;
      .ant-menu-title-content {
        color: @color-primary !important;
      }
    }
  }
  .header-button-mobile {
    padding: 20px 40px;
    margin-bottom: 20px;
    text-align: center;
    .button-sign-in {
      color: unset;
      border: unset;
      box-shadow: unset;
      padding: 0 20px !important;
    }
  
    .button-sign-in:hover {
      color: @color-primary;
      border: 1px solid @color-primary;
      box-shadow: unset;
      transition: 0.125s;
      padding: 0 20px !important;
    }
  
    .button-join {
      margin: 30px 0 20px;
      width: 100%;
      color: white;
      font-weight: bold;
      font-size: 25px !important;
      border-radius: 10px;
      height: 45px;
    }
  }
}

@media only screen
and (orientation: landscape)
and (min-device-width: 1024px)
and (max-device-height: 810px) {
  #header-mobile{
    position: fixed;
    width: 100%;
    height: 80px;
    padding: 0 40px;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    box-shadow: 0 -15px 20px 5px #b3b3b3;
  
    .header-logo {
      width: 25%;
    }
  
    .button-sign-in {
      color: unset;
      border: unset;
      box-shadow: unset;
      padding: 0 20px !important;
      margin-right: 10px;
    }
  
    .button-sign-in:hover {
      color: @color-primary;
      border: 1px solid @color-primary;
      box-shadow: unset;
      transition: 0.125s;
      padding: 0 20px !important;
    }
  
    .button-join {
      color: white;
      font-weight: bold;
    }
  }
  
  #header-mobile-content{
    display: flex;
    align-items: center;
    padding: 20px 50px;
    background-color: white;
  
    .header-logo {
      width: 25%;
      margin: auto;
    }
  }
  .header-menu-mobile {
    padding: 0 40px;
    .ant-menu-title-content {
      color: @color-border-light-gray !important;
      font-weight: 600 !important;
    }
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: unset !important;
      .ant-menu-title-content {
        color: @color-primary !important;
      }
    }
  }
  .header-button-mobile {
    padding: 20px 50px;
    margin-bottom: 20px;
    text-align: center;
    .button-sign-in {
      color: unset;
      border: unset;
      box-shadow: unset;
      padding: 0 20px !important;
    }
  
    .button-sign-in:hover {
      color: @color-primary;
      border: 1px solid @color-primary;
      box-shadow: unset;
      transition: 0.125s;
      padding: 0 20px !important;
    }
  
    .button-join {
      margin: 30px 0 20px;
      width: 100%;
      color: white;
      font-weight: bold;
      font-size: 25px !important;
      border-radius: 10px;
      height: 45px;
    }
  }
}

/* ipad pro 12.9*/
@media only screen
and (orientation: portrait)
and (min-device-width: 834px)
and (max-device-height: 1366px) {
  #header-mobile{
    position: fixed;
    width: 100%;
    height: 80px;
    padding: 0 50px;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    box-shadow: 0 -15px 20px 5px #b3b3b3;
  
    .header-logo {
      width: 25%;
    }
  
    .button-sign-in {
      color: unset;
      border: unset;
      box-shadow: unset;
      padding: 0 20px !important;
      margin-right: 10px;
    }
  
    .button-sign-in:hover {
      color: @color-primary;
      border: 1px solid @color-primary;
      box-shadow: unset;
      transition: 0.125s;
      padding: 0 20px !important;
    }
  
    .button-join {
      color: white;
      font-weight: bold;
    }
  }
  
  #header-mobile-content{
    display: flex;
    align-items: center;
    padding: 20px 50px;
    background-color: white;
  
    .header-logo {
      width: 25%;
      margin: auto;
    }
  }
  .header-menu-mobile {
    padding: 0 40px;
    .ant-menu-title-content {
      color: @color-border-light-gray !important;
      font-weight: 600 !important;
    }
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: unset !important;
      .ant-menu-title-content {
        color: @color-primary !important;
      }
    }
  }
  .header-button-mobile {
    padding: 20px 60px;
    margin-bottom: 20px;
    text-align: center;
    .button-sign-in {
      color: unset;
      border: unset;
      box-shadow: unset;
      padding: 0 20px !important;
    }
  
    .button-sign-in:hover {
      color: @color-primary;
      border: 1px solid @color-primary;
      box-shadow: unset;
      transition: 0.125s;
      padding: 0 20px !important;
    }
  
    .button-join {
      margin: 30px 0 20px;
      width: 100%;
      color: white;
      font-weight: bold;
      font-size: 25px !important;
      border-radius: 10px;
      height: 45px;
    }
  }
}

@media only screen
and (orientation: landscape)
and (min-device-width: 1112px)
and (max-device-height: 1024px) {
  #header-mobile{
    position: fixed;
    width: 100%;
    height: 80px;
    padding: 0 50px;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    box-shadow: 0 -15px 20px 5px #b3b3b3;
  
    .header-logo {
      width: 25%;
    }
  
    .button-sign-in {
      color: unset;
      border: unset;
      box-shadow: unset;
      padding: 0 20px !important;
      margin-right: 10px;
    }
  
    .button-sign-in:hover {
      color: @color-primary;
      border: 1px solid @color-primary;
      box-shadow: unset;
      transition: 0.125s;
      padding: 0 20px !important;
    }
  
    .button-join {
      color: white;
      font-weight: bold;
    }
  }
  
  #header-mobile-content{
    display: flex;
    align-items: center;
    padding: 20px 50px;
    background-color: white;
  
    .header-logo {
      width: 25%;
      margin: auto;
    }
  }
  .header-menu-mobile {
    padding: 0 40px;
    .ant-menu-title-content {
      color: @color-border-light-gray !important;
      font-weight: 600 !important;
    }
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: unset !important;
      .ant-menu-title-content {
        color: @color-primary !important;
      }
    }
  }
  .header-button-mobile {
    padding: 20px 60px;
    margin-bottom: 20px;
    text-align: center;
    .button-sign-in {
      color: unset;
      border: unset;
      box-shadow: unset;
      padding: 0 20px !important;
    }
  
    .button-sign-in:hover {
      color: @color-primary;
      border: 1px solid @color-primary;
      box-shadow: unset;
      transition: 0.125s;
      padding: 0 20px !important;
    }
  
    .button-join {
      margin: 30px 0 20px;
      width: 100%;
      color: white;
      font-weight: bold;
      font-size: 25px !important;
      border-radius: 10px;
      height: 45px;
    }
  }
}