#landing {
  width: 100%;
  padding-top: 80px;

  @media only screen
  and (max-device-width: 480px) {
    padding-top: 60px !important;
  }

  @media only screen
  and (orientation: landscape)
  and (max-device-width: 950px) {
    padding-top: 80px !important;
  }

}