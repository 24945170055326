@import '../../../variable.less';

@media only screen and (max-device-width: 480px) {
  #SectionHeadContentAnalysis {
    padding: 20px !important;

    .content-head {
      padding: unset !important;
      .content-title {
        width: 100%;
        h1.ant-typography {
          margin: 20px 0 !important;
          font-size: 30px !important;
          line-height: normal !important;
        }

        .ant-typography.description {
          font-size: 14px !important;
          line-height: normal !important;
        }

        .ant-btn {
          width: 100% !important;
          font-size: 20px !important;
          height: 50px !important;
          padding: 0 50px !important;
        }
      }

      .ant-row {
        flex-flow: wrap-reverse !important;
      }

      .ant-image {
        width: 100% ;
      }
      .content-bottom {
        padding: 40px 0 20px 0 !important;
        .ant-row {
          flex-flow: row wrap !important;
        }
  
        h2.ant-typography {
          margin: 10px 0 0 0 !important;
        }
        .bottom-info {
          width: 80% !important;
          margin-bottom: 20px !important;
          .ant-image-img {
            width: 100% !important;
          }
    
          .ant-typography.description {
            font-size: 14px !important;
          }
    
        }
      }
    }



  }
}

@media only screen
and (orientation: landscape) 
and (max-device-width: 950px) {
  #SectionHeadContentAnalysis {
    padding: 20px !important;

    .content-head {
      padding: unset !important;
      .content-title {
        width: 100%;
        h1.ant-typography {
          margin: 20px 0 !important;
          font-size: 24px !important;
          line-height: normal !important;
        }

        .ant-typography.description {
          font-size: 14px !important;
          line-height: normal !important;
        }

        .ant-btn {
          width: 100% !important;
        }
      }

      .ant-row {
        flex-flow: wrap-reverse !important;
      }
      .ant-image {
        width: 100% ;
      }
      .content-bottom {
        padding: 40px 0 20px 0 !important;
  
        h2.ant-typography {
          margin: 10px 0 0 0 !important;
        }
        .bottom-info {
          width: 100% !important;
          margin-bottom: 20px !important;
          .ant-image-img {
            width: 100% !important;
          }
    
          .ant-typography.description {
            font-size: 14px !important;
          }
    
        }
      }
    }



  }
  
}

/* ipad */
@media only screen
and (orientation: portrait)
and (min-device-width: 768px)
and (max-device-height: 1024px) {
  #SectionHeadContentAnalysis {
    padding: 40px !important;

    .content-head {
      padding: unset !important;
      .content-title {
        width: 100%;
        h1.ant-typography {
          margin: 20px 0 !important;
          font-size: 30px !important;
          line-height: normal !important;
        }

        .ant-typography.description {
          font-size: 14px !important;
          line-height: normal !important;
        }

        .ant-btn {
          font-size: 20px !important;
          height: 50px !important;
          padding: 0 50px !important;
        }
      }

      .ant-row {
        flex-flow: wrap-reverse !important;
      }
    }

    .content-bottom {
      padding: 40px 0 20px 0 !important;
      .ant-row {
        flex-flow: row wrap !important;
      }

      h2.ant-typography {
        margin: 10px 0 0 0 !important;
      }
      .bottom-info {
        width: 100% !important;
        h2.ant-typography {
          font-size: 18px !important;
          margin-top: 0 !important;
        }
        .ant-image-img {
          width: 100% !important;
        }
  
        .ant-typography.description {
          font-size: 14px !important;
        }
  
      }
    }


  }
}

@media only screen
and (orientation: landscape)
and (min-device-width: 1024px)
and (max-device-height: 810px) {
  #SectionHeadContentAnalysis {
    padding: 50px !important;

    .content-head {
      padding: unset !important;
      .content-title {
        width: 90%;
        h1.ant-typography {
          margin: 20px 0 !important;
          font-size: 35px !important;
          line-height: normal !important;
        }

        .ant-typography.description {
          font-size: 16px !important;
          line-height: normal !important;
        }
      }

      .ant-row {
        flex-flow: wrap-reverse !important;
      }
    }

    .content-bottom {
      padding: 40px 0 20px 0 !important;

      h2.ant-typography {
        margin: 10px 0 0 0 !important;
      }
      .bottom-info {
        width: 100% !important;
        margin-bottom: 20px !important;
        .ant-image-img {
          width: 100% !important;
        }
  
        .ant-typography.description {
          font-size: 14px !important;
        }
  
      }
    }


  }
}
/* ipad pro 12.9*/
@media only screen
and (orientation: portrait)
and (min-device-width: 834px)
and (max-device-height: 1366px) {
  #SectionHeadContentAnalysis {
    padding: 60px !important;

    .content-head {
      padding: unset !important;
      .content-title {
        width: 100%;
        h1.ant-typography {
          margin: 20px 0 !important;
          font-size: 40px !important;
          line-height: normal !important;
        }

        .ant-typography.description {
          font-size: 18px !important;
          line-height: normal !important;
        }

        .ant-btn {
          font-size: 20px !important;
          height: 50px !important;
          padding: 0 50px !important;
        }
      }

      .ant-row {
        flex-flow: wrap-reverse !important;
      }
    }

    .content-bottom {
      padding: 40px 0 20px 0 !important;
      .ant-row {
        flex-flow: row wrap !important;
      }

      h2.ant-typography {
        margin: 10px 0 0 0 !important;
      }
      .bottom-info {
        width: 100% !important;
        h2.ant-typography {
          font-size: 18px !important;
          margin-top: 0 !important;
        }
        .ant-image-img {
          width: 100% !important;
        }
  
        .ant-typography.description {
          font-size: 14px !important;
        }
  
      }
    }


  }
}

@media only screen
and (orientation: landscape)
and (min-device-width: 1112px)
and (max-device-height: 1024px) {
  #SectionHeadContentAnalysis {
    padding: 60px !important;

    .content-head {
      padding: unset !important;
      .content-title {
        width: 90%;
        h1.ant-typography {
          margin: 20px 0 !important;
          font-size: 45px !important;
          line-height: normal !important;
        }

        .ant-typography.description {
          font-size: 18px !important;
          line-height: normal !important;
        }
      }

      .ant-row {
        flex-flow: wrap-reverse !important;
      }
    }

    .content-bottom {
      padding: 40px 0 20px 0 !important;

      h2.ant-typography {
        margin: 10px 0 0 0 !important;
        font-size: 25px !important;
      }
      .bottom-info {
        width: 100% !important;
        margin-bottom: 20px !important;
        .ant-image-img {
          width: 100% !important;
        }
  
        .ant-typography.description {
          font-size: 18px !important;
        }
  
      }
    }


  }
}