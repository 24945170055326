@import '../../../variable.less';

@media only screen and (max-device-width: 480px) {
  #SectionMiddleContentAbout {
    padding: 20px !important;
    
    .content-head {
      padding: unset !important;

      .content-head-title {
        .content-subtitle {
          width: 80% !important;
        }
        
      }

      .ant-row {
        margin-top: 30px;
        padding: unset !important;
        display: flex;
        flex-flow: nowrap !important;
        overflow: auto !important;
  
        .ant-card-body {
          padding: 20px !important;
          height: 280px !important;
        }

        .description {
          margin-top: 20px;
          .ant-typography {
            font-size: 14px !important;
          }
        }
      }

    }
  }
}

@media only screen
and (orientation: landscape) 
and (max-device-width: 950px) {
  #SectionMiddleContentAbout {
    padding: 20px !important;
    
    .content-head {
      padding: unset !important;

      .content-head-title {
        .content-subtitle {
          width: 80% !important;
        }
        
      }

      .ant-row {
        margin-top: 30px;
        padding: unset !important;
        display: flex;
        flex-flow: nowrap !important;
        overflow: auto !important;
  
        .ant-card-body {
          padding: 20px !important;
          height: 260px !important;
        }

        .description {
          margin-top: 20px;
          .ant-typography {
            font-size: 14px !important;
          }
        }
      }

    }
  }
  
}

/* ipad */
@media only screen
and (orientation: portrait)
and (min-device-width: 768px)
and (max-device-height: 1024px) {
  #SectionMiddleContentAbout {
    padding: 20px !important;
    
    .content-head {
      padding: unset !important;

      .content-head-title {
        .content-subtitle {
          width: 80% !important;
        }
        
      }

      .ant-row {
        margin-top: 30px;
        padding: unset !important;
        display: flex;
        flex-flow: nowrap !important;
        overflow: auto !important;
        .ant-card-body {
          padding: 10px !important;
          height: 300px !important;

          .ant-image {
            width: 50px !important;
          }
          h3.ant-typography {
            font-size: 16px !important;
          }
          .description{
            font-size: 12px !important;
          }
        }

        .description {
          margin-top: 20px;
          .ant-typography {
            font-size: 14px !important;
          }
        }
      }

    }
  }
}

@media only screen
and (orientation: landscape)
and (min-device-width: 1024px)
and (max-device-height: 810px) {
  #SectionMiddleContentAbout {
    padding: 20px !important;
    
    .content-head {
      padding: unset !important;

      .content-head-title {
        .content-subtitle {
          width: 80% !important;
        }
        
      }

      .ant-row {
        margin-top: 30px;
        padding: unset !important;
        display: flex;
        flex-flow: nowrap !important;
        overflow: auto !important;
        .ant-col-md-6 {
          padding: 0 20px !important;
        }
        .ant-card-body {
          padding: 20px !important;
          height: 300px !important;

          .ant-image {
            width: 50px !important;
          }
          h3.ant-typography {
            font-size: 16px !important;
          }
          .description{
            font-size: 12px !important;
          }
        }

        .description {
          margin-top: 20px;
          .ant-typography {
            font-size: 14px !important;
          }
        }
      }

    }
  }
}
/* ipad pro 12.9*/
@media only screen
and (orientation: portrait)
and (min-device-width: 834px)
and (max-device-height: 1366px) {
  #SectionMiddleContentAbout {
    padding: 20px !important;
    
    .content-head {
      padding: unset !important;

      .content-head-title {
        .content-subtitle {
          width: 80% !important;
        }
        
      }

      .ant-row {
        margin-top: 30px;
        padding: unset !important;
        display: flex;
        flex-flow: nowrap !important;
        overflow: auto !important;
        .ant-col-md-6 {
          padding: 0 20px !important;
        }
        .ant-card-body {
          padding: 10px !important;
          height: 320px !important;
          .ant-image {
            width: 50px !important;
          }
          h3.ant-typography {
            font-size: 18px !important;
          }
        }

        .description {
          .ant-typography {
            font-size: 16px !important;
          }
        }
      }

    }
  }
}

@media only screen
and (orientation: landscape)
and (min-device-width: 1112px)
and (max-device-height: 1024px) {
  #SectionMiddleContentAbout {
    padding: 40px !important;
    
    .content-head {
      padding: unset !important;

      .content-head-title {
        h1.ant-typography{
          font-size: 45px !important;
        }
        .content-subtitle {
          width: 80% !important;
          font-size: 18px !important;
        }
        
      }

      .ant-row {
        margin-top: 30px;
        padding: unset !important;
        display: flex;
        flex-flow: nowrap !important;
        overflow: auto !important;
  
        .ant-card-body {
          padding: 20px !important;
          height: 330px !important;
          h3.ant-typography {
            font-size: 22px !important;
          }
        }

        .description {
          margin-top: 20px;
          .ant-typography {
            font-size: 18px !important;
          }
        }
      }

    }
  }
}