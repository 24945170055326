@import '../../../variable.less';

#SectionHeadContent {
  background-color: @color-white;

  .content-head {
    padding: 40px 90px 45px;

    .content-title {
      h1.ant-typography {
        font-size: 40px;
        margin: 65px 0 30px;
      }
      .ant-typography.description {
        font-size: 18px;
      }

      .ant-btn {
        margin-top: 30px;
        border-radius: 15px;
        padding: 5px 50px !important;
        color: white;
        font-weight: bold;
        height: 60px;
        line-height: 50px !important;
      }

    }

    .ant-image {
      width: 90% !important;
    }

    .content-case {
      text-align: center;
  
      h3.ant-typography {
        color: @color-primary;
        font-weight: 600;
        margin: 20px 0 40px !important;
      }
      
      .ant-row {
        align-items: center;
        justify-content: center;
  
        .ant-col {
          margin-bottom: 20px;
        }
      }
  
      .ant-image {
        width: auto !important;
      }
  }

  
  }
  
}