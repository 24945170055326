@import '../../../variable.less';

#SectionBottomContentAbout {  
  padding: 100px 90px;
  .content-head {
    padding: 30px 0;

    .content-title {
      h1.ant-typography {
        margin: 100px 0 30px;
      }
      h3.ant-typography {
        margin: 30px 0 30px !important;
      }

      @media only screen
        and (min-width: 1400px) {
          .ant-typography.description {
            font-size: 20px !important;
            line-height: 27px !important;
          }
        }

      button {
        margin-top: 30px;
        font-size: 14px;
      }

    }

  }

  .content-bottom {
    padding: 30px 0;
    text-align: center;
    .ant-row {
      padding: 20px;
    }

    .ant-image {
      width: 100%;
      height: 360px;
      border-radius: 20px;

      .ant-image-img {
        height: 360px;
      }
    }

    .content-head-title {
      margin: 80px 50px 50px 50px;
      text-align: center;
      
      h1.ant-typography {
        margin-bottom: 15px !important;
      }
      
    }

  }
  
}