@import '../../../variable.less';

#SectionMiddleContentAbout {
  
  .content-head {
    padding: 60px 90px;

    .content-head-title {
      text-align: center;
      
      h1.ant-typography {
        margin-bottom: 15px !important;
      }

      .content-subtitle {
        width: 40%;
        font-size: 16px;
        margin: auto;
      }
      
    }

    .ant-card-body {
      padding: 40px;
      height: 350px;

      @media only screen
      and (max-width: 1400px) {
        padding: 20px;
        height: 300px;
      }

      @media only screen
      and (min-width: 1400px)
      and (max-width: 1600px) {
        height: 350px;
      }
    }

    .ant-row {
      margin-top: 80px;

      .description {
        margin-top: 20px;
        @media only screen
        and (min-width: 1400px) {
          .ant-typography {
            font-size: 18px !important;
          }
        }
      }
      
    }

  }

  .content-middle {
    padding: 60px 0 60px 60px;
    background-color: @color-primary;
    color: @color-white;
    
    .ant-typography {
      color: @color-white;
    }

    .content-middle-title {
      text-align: center;
      
      h1.ant-typography {
        margin-bottom: 15px !important;
        color: @color-white;
      }
      
    }

    .content-integration {
      margin: 50px 0;

      .description {
        padding-right: 60px;
        h1.ant-typography {
          margin: 20px 0 !important;
        }

      }

      .action-button-slide {
        width: 35%;
        display: flex;
        margin-top: 50px;
        justify-content: space-between;

        button {
          width: 50px;
          height: 50px;
          padding: 10px;
          border-radius: 100%;
          text-align: center;
          background-color: transparent;
          color: @color-white;
        }
      }

      .section-carousel {
        .content-carousel {
          width: 300px;
          height: max-content;
          background-color: white;
          border-radius: 20px;
          
          .ant-image {
            width: 100%;
            border-radius: 20px;
          }

          .description {
            width: 50%;
            padding: 20px;
            margin: 30px auto;
            text-align: center;
            font-size: 20px;
            
            .ant-typography {
              color: black;
              font-weight: bold;
            }
          }
  
        }
  
        .ant-carousel .slick-dots-bottom {
          bottom: -35px;
        }

        .ant-carousel .slick-initialized .slick-slide {
          width: fit-content !important;
          margin-right: 20px;
        }
        
        .slick-arrow {
          width: 10px;
          height: 10px;
        }
  
        .slick-next {
          border: solid black;
          border-width: 0 3px 3px 0;
          display: inline-block;
          padding: 3px;
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
        }
  
        .slick-prev {
          border: solid black;
          border-width: 0 3px 3px 0;
          display: inline-block;
          padding: 3px;
          transform: rotate(135deg);
          -webkit-transform: rotate(135deg);
        }
  
      }
    }

  }
  
}
