@import '../../../variable.less';

#SectionHeadContentAbout {
  background-color: @color-white;

  .content-head {
    padding: 40px 90px 45px;

    .content-title {
      width: 90%;
      h1.ant-typography {
        font-size: 40px;
        margin: 100px 0 40px;
      }

      .ant-btn {
        margin-top: 30px;
        border-radius: 15px;
        padding: 5px 50px !important;
        color: white;
        font-weight: bold;
        height: 60px;
        line-height: 50px !important;
      }

    }

    // .ant-image {
    //   width: 90% !important;
    // }

  }

  .content-bottom {
    padding: 100px 0;

    .bottom-title {
      width: 80%;
      h2.ant-typography {
        margin-bottom: 20px !important;
      }
      .ant-typography.description {
        margin-bottom: 15px;
      }

    }

    .bottom-info {
      width: 80%;
      margin-bottom: 20px;

      h1.ant-typography {
        color: @color-primary;
      }

    }

  }
  
}