@import '../../../variable.less';

@media only screen
and (max-device-width: 480px) {
  #SectionHeadContentAbout {
    padding: 20px !important;
    .content-head {
      padding: unset !important;

      .content-title {
        width: 100% !important;

        h1.ant-typography {
          margin: 20px 0 !important;
          font-size: 30px !important;
          line-height: normal !important;
        }
        .ant-typography.description {
          margin-bottom: 20px !important;
          font-size: 14px !important;
          line-height: normal !important;
        }
  
        .ant-btn {
          width: 100% !important;
          font-size: 20px !important;
          height: 50px !important;
          padding: 0 50px !important;
          margin: 30px 0 !important;
        }
      }

      .ant-row {
        flex-flow: wrap-reverse !important;
      }

      .content-bottom {
        padding: 0 !important;
        h2.ant-typography {
          font-size: 30px !important;
        }
        .ant-row {
          flex-flow: row wrap !important;
        }

        .bottom-title {
          width: 100% !important;
          margin-bottom: 20px !important;
        }

        .bottom-info {
          width: 100% !important;
          h1.ant-typography {
            font-size: 40px !important;
          }
        }
      }

    }
  }
}

@media only screen
and (orientation: landscape) 
and (max-device-width: 950px) {
  #SectionHeadContentAbout {
    padding: 20px !important;
    .content-head {
      padding: unset !important;

      .content-title {
        width: 100% !important;

        h1.ant-typography {
          margin: 20px 0 !important;
          font-size: 24px !important;
          line-height: normal !important;
        }
        .ant-typography.description {
          margin-bottom: 20px !important;
          font-size: 14px !important;
          line-height: normal !important;
        }

        .ant-btn {
          width: 100% !important;
        }
      }

      .ant-row {
        flex-flow: wrap-reverse !important;
      }

      .content-bottom {
        padding: 20px 0 0 0 !important;
        .ant-row {
          flex-flow: row wrap !important;
        }

        .bottom-title {
          width: 100% !important;
          margin-bottom: 20px !important;
        }

        .bottom-info {
          width: 100% !important;
        }
      }

    }
  }
}

/* ipad */
@media only screen
and (orientation: portrait)
and (min-device-width: 768px)
and (max-device-height: 1024px) {
  #SectionHeadContentAbout {
    padding: 40px !important;
    .content-head {
      padding: unset !important;

      .content-title {
        width: 100% !important;

        h1.ant-typography {
          margin: 20px 0 !important;
          font-size: 30px !important;
          line-height: normal !important;
        }
        .ant-typography.description {
          margin-bottom: 20px !important;
          font-size: 14px !important;
          line-height: normal !important;
        }
  
        .ant-btn {
          font-size: 20px !important;
          height: 50px !important;
          padding: 0 50px !important;
          margin: 30px 0 !important;
        }
      }

      .ant-row {
        flex-flow: wrap-reverse !important;
      }

      .content-bottom {
        padding: 0 !important;
        h2.ant-typography {
          font-size: 25px !important;
        }
        .ant-row {
          flex-flow: row wrap !important;
        }

        .bottom-title {
          width: 100% !important;
          margin-bottom: 20px !important;
        }

        .bottom-info {
          width: 100% !important;
          h1.ant-typography {
            font-size: 30px !important;
          }
        }
      }

    }
  }
}

@media only screen
and (orientation: landscape)
and (min-device-width: 1024px)
and (max-device-height: 810px) {
  #SectionHeadContentAbout {
    padding: 40px !important;
    .content-head {
      padding: unset !important;

      .content-title {
        width: 100% !important;

        h1.ant-typography {
          margin: 20px 0 !important;
          font-size: 30px !important;
          line-height: normal !important;
        }
        .ant-typography.description {
          margin-bottom: 20px !important;
          font-size: 16px !important;
          line-height: normal !important;
        }
      }

      .ant-row {
        flex-flow: wrap-reverse !important;
      }

      .content-bottom {
        padding: 20px 0 0 0 !important;
        .ant-row {
          flex-flow: row wrap !important;
        }

        .bottom-title {
          width: 100% !important;
          margin-bottom: 20px !important;
        }

        .bottom-info {
          width: 100% !important;
        }
      }

    }
  }
}
/* ipad pro 12.9*/
@media only screen
and (orientation: portrait)
and (min-device-width: 834px)
and (max-device-height: 1366px) {
  #SectionHeadContentAbout {
    padding: 50px !important;
    .content-head {
      padding: unset !important;

      .content-title {
        width: 100% !important;

        h1.ant-typography {
          margin: 20px 0 !important;
          font-size: 35px !important;
          line-height: normal !important;
        }
        .ant-typography.description {
          margin-bottom: 20px !important;
          font-size: 18px !important;
          line-height: normal !important;
        }
  
        .ant-btn {
          font-size: 20px !important;
          height: 50px !important;
          padding: 0 50px !important;
          margin: 30px 0 !important;
        }
      }

      .ant-row {
        flex-flow: wrap-reverse !important;
      }

      .content-bottom {
        padding: 0 !important;
        h2.ant-typography {
          font-size: 25px !important;
        }
        .ant-row {
          flex-flow: row wrap !important;
        }

        .bottom-title {
          width: 100% !important;
          margin-bottom: 20px !important;
          .ant-typography.description {
            font-size: 18px !important;
          }
        }

        .bottom-info {
          width: 100% !important;
          h1.ant-typography {
            font-size: 30px !important;
          }
        }
      }

    }
  }
}

@media only screen
and (orientation: landscape)
and (min-device-width: 1112px)
and (max-device-height: 1024px) {
  #SectionHeadContentAbout {
    padding: 60px !important;
    .content-head {
      padding: unset !important;

      .content-title {
        width: 100% !important;

        h1.ant-typography {
          margin: 20px 0 !important;
          font-size: 45px !important;
          line-height: normal !important;
        }
        .ant-typography.description {
          margin-bottom: 20px !important;
          font-size: 18px !important;
          line-height: normal !important;
        }
      }

      .ant-row {
        flex-flow: wrap-reverse !important;
      }

      .content-bottom {
        padding: 20px 0 0 0 !important;
        .ant-row {
          flex-flow: row wrap !important;
        }

        .bottom-title {
          width: 100% !important;
          margin-bottom: 20px !important;
          h2.ant-typography {
            font-size: 30px;
          }
          .ant-typography.description {
            font-size: 18px !important;
          }
        }

        .bottom-info {
          width: 100% !important;
          h1.ant-typography {
            font-size: 45px !important;
          }

          .ant-typography {
            font-size: 18px; 
          }
        }
      }

    }
  }
}