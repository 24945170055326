@import '../../../variable.less';

@media only screen and (max-device-width: 480px) {
  #SectionBottomContent {
    padding: 20px !important;
    .content-head {
      padding: unset !important;

      .ant-row {
        flex-flow: row wrap !important;
        .box {
          padding: 10px !important;
          .ant-typography {
            font-size: 12px !important;
          }
          i {
            font-size: 30px !important;
          }
        }
      }

      .content-title {
        width: 100% !important;
        margin-bottom: 20px !important;
        h2.ant-typography {
          margin: 20px 0 10px 0 !important;
        }
        h3.ant-typography {
          margin: 10px 0 10px 0 !important;
        }
      }
    }

    .content-certificated {
      padding: unset !important;
      h1.ant-typography {
        margin: 20px 0 !important
      }

      .ant-row {
  
        .ant-col {
          width: 100px !important;
          height: fit-content !important;
          padding: 10px !important;
          margin: unset !important;
          
          background-color: unset !important;
          .ant-image {
            padding: 15px 20px !important;
            background-color: white !important;
            height: 80px !important;
            border-radius: 10px !important;
          }
        }
  
      }
    }
  }
}

@media only screen
and (orientation: landscape)
and (max-device-width: 950px) {
  #SectionBottomContent {
    padding: 20px !important;
    .content-head {
      padding: unset !important;

      .ant-row {
        flex-flow: row wrap !important;
        .box {
          padding: 10px !important;
          .ant-typography {
            font-size: 12px !important;
          }
          i {
            font-size: 30px !important;
          }
        }
      }

      .content-title {
        width: 100% !important;
        margin-bottom: 20px !important;
        h1.ant-typography {
          font-size: 30px !important;
          margin: 20px 0 10px 0 !important;
        }
        h3.ant-typography {
          margin: 10px 0 10px 0 !important;
        }
      }
    }

    .content-certificated {
      padding: unset !important;
      h1.ant-typography {
        margin: 20px 0 !important;
        font-size: 30px !important;
      }

      .ant-row {
  
        .ant-col {
          width: 100px !important;
          padding: 10px !important;
          margin: unset !important;
          
          background-color: unset !important;
          .ant-image {
            padding: 10px 20px !important;
            background-color: white !important;
            // height: 120px !important;
            border-radius: 10px !important;
          }
        }
  
      }
    }
  }
}

/* ipad */
@media only screen
and (orientation: portrait)
and (min-device-width: 768px)
and (max-device-height: 1024px) {
  #SectionBottomContent {
    padding: 40px !important;
    .content-head {
      padding: unset !important;

      .ant-row {
        flex-flow: row wrap !important;
        .ant-col-md-12 {
          padding: 0 15px !important;
        }
        .box {
          padding: 10px !important;
          height: 100px !important;
          margin-bottom: 10px !important;
          .ant-typography {
            font-size: 10px !important;
          }
          i {
            font-size: 18px !important;
          }
        }
      }

      .content-title {
        width: 100% !important;
        margin-bottom: 20px !important;
        .ant-typography.description {
          font-size: 16px !important;
        }
        h1.ant-typography {
          font-size: 30px;
        }
        h2.ant-typography {
          margin: 20px 0 10px 0 !important;
        }
        h3.ant-typography {
          margin: 10px 0 10px 0 !important;
        }
      }
    }

    .content-certificated {
      padding: unset !important;
      h1.ant-typography {
        margin: 20px 0 !important;
        font-size: 30px !important;
      }

      .ant-row {
  
        .ant-col {
          width: 100px !important;
          height: fit-content !important;
          padding: 10px !important;
          margin: unset !important;
          
          background-color: unset !important;
          .ant-image {
            padding: 15px 20px !important;
            background-color: white !important;
            border-radius: 10px !important;
          }
        }
  
      }
    }
  }
}

@media only screen
and (orientation: landscape)
and (min-device-width: 1024px)
and (max-device-height: 810px) {
  #SectionBottomContent {
    padding: 40px !important;
    .content-head {
      padding: unset !important;

      .ant-row {
        flex-flow: row wrap !important;
        .box {
          padding: 10px !important;
          .ant-typography {
            font-size: 14px !important;
          }
          i {
            font-size: 30px !important;
          }
        }
      }

      .content-title {
        width: 100% !important;
        margin-bottom: 20px !important;
        h1.ant-typography {
          font-size: 30px !important;
          margin: 20px 0 10px 0 !important;
        }
        h3.ant-typography {
          margin: 10px 0 10px 0 !important;
        }
      }
    }

    .content-certificated {
      padding: unset !important;
      h1.ant-typography {
        margin: 20px 0 !important;
        font-size: 30px !important;
      }

      .ant-row {
  
        .ant-col {
          width: 100px !important;
          padding: 10px !important;
          margin: unset !important;
          
          background-color: unset !important;
          .ant-image {
            padding: 10px 20px !important;
            background-color: white !important;
            // height: 120px !important;
            border-radius: 10px !important;
          }
        }
  
      }
    }
  }
}

/* ipad pro 12.9*/
@media only screen
and (orientation: portrait)
and (min-device-width: 834px)
and (max-device-height: 1366px) {
  #SectionBottomContent {
    padding: 40px !important;
    .content-head {
      padding: unset !important;

      .ant-row {
        flex-flow: row wrap !important;
        .ant-col-md-12 {
          padding: 0 15px !important;
        }
        .box {
          padding: 10px !important;
          height: 100px !important;
          margin-bottom: 10px !important;
          .ant-typography {
            font-size: 12px !important;
          }
          i {
            font-size: 18px !important;
          }
        }
      }

      .content-title {
        width: 100% !important;
        margin-bottom: 20px !important;
        .ant-typography.description {
          font-size: 16px !important;
        }
        h1.ant-typography {
          font-size: 30px;
        }
        h2.ant-typography {
          margin: 20px 0 10px 0 !important;
        }
        h3.ant-typography {
          margin: 10px 0 10px 0 !important;
        }
      }
    }

    .content-certificated {
      padding: unset !important;
      h1.ant-typography {
        margin: 20px 0 !important;
        font-size: 30px !important;
      }

      .ant-row {
  
        .ant-col {
          width: 100px !important;
          height: fit-content !important;
          padding: 10px !important;
          margin: unset !important;
          
          background-color: unset !important;
          .ant-image {
            padding: 15px 20px !important;
            background-color: white !important;
            border-radius: 10px !important;
          }
        }
  
      }
    }
  }
}

@media only screen
and (orientation: landscape)
and (min-device-width: 1112px)
and (max-device-height: 1024px) {
  #SectionBottomContent {
    padding: 40px !important;
    .content-head {
      padding: unset !important;

      .ant-row {
        flex-flow: row wrap !important;
        .box {
          padding: 10px !important;
          .ant-typography {
            font-size: 16px !important;
          }
          i {
            font-size: 30px !important;
          }
        }
      }

      .content-title {
        width: 100% !important;
        margin-bottom: 20px !important;
        h1.ant-typography {
          font-size: 30px !important;
          margin: 20px 0 10px 0 !important;
        }
        h3.ant-typography {
          margin: 10px 0 10px 0 !important;
        }
      }
    }

    .content-certificated {
      padding: unset !important;
      h1.ant-typography {
        margin: 20px 0 !important;
        font-size: 30px !important;
      }

      .ant-row {
  
        .ant-col {
          width: 100px !important;
          padding: 10px !important;
          margin: unset !important;
          
          background-color: unset !important;
          .ant-image {
            padding: 10px 20px !important;
            background-color: white !important;
            // height: 120px !important;
            border-radius: 10px !important;
          }
        }
  
      }
    }
  }
}